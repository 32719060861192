/* LoginPage.css */
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4eee4; /* Adjust the color as needed */
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  margin-right: 50px; /* Adjust space between logo and login box as needed */
}

.login-container {
  width: 340px; /* Adjust width as needed */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #1877f2;
  cursor: pointer;
  font-size: 16px;
}

.login-button:hover {
  background-color: #165ab5;
}

/* Add styles for the logo */
.login-logo {
  max-width: 260px; /* Adjust to your preference */
  height: auto; /* maintain aspect ratio */
}
