/* File: src/components/Dashboard/DomesticFlights/domesticFlights.module.css */
/* Purpose: Centralized styles for all Domestic Flights components. */

/* General Layout for the Domestic Flights Frontpage */
.domesticFlightsFrontpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff; /* Light background */
}

/* Search Bar Layout */
.searchBar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1000px;
    gap: 15px;
    margin-bottom: 25px;
}

/* Dropdown Styles */
.dropdownWrapper {
    min-width: 120px;
    max-width: 250px;
    flex-shrink: 1;
}

.seasonsDropdown {
    max-width: 150px;
}

.originDropdown,
.destinationDropdown {
    max-width: 250px;
}

/* Rates Section Styling */
.ratesContainer {
    width: 100%;
    max-width: 1200px;
    margin-top: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
}

.noRatesMessage {
    text-align: center;
    font-size: 16px;
    color: #6c757d; /* Subtle gray text */
}

.errorMessage {
    color: #dc3545; /* Bootstrap red for errors */
    font-weight: bold;
}

/* Shared Box for Quote Generator and Policy Section */
.sharedBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1000px;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    gap: 30px;
    margin-bottom: 30px;
}

/* Quote Generator Section */
.quoteGenerator {
    flex: 1;
    max-width: 480px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Policy Section */
.policySection {
    flex: 1;
    max-width: 480px;
    min-height: 350px;
    overflow-y: auto;
    padding-left: 20px;
    border-left: 2px solid #ddd;
}

/* Section Titles */
.quoteGeneratorTitle,
.policySection h3 {
    font-size: 20px;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 20px;
}

/* Quote Row Styling */
.quoteRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
}

.quoteRow label {
    flex: 0 0 160px;
    text-align: left;
    font-size: 16px;
    color: #343a40;
}

.quoteRow .ant-input-number {
    flex: 1;
    max-width: 150px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 5px;
}

/* Dynamic Quote Summary */
.quoteResults {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
    text-align: center;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.previewTable {
    width: auto;
    margin: 0 auto;
    border-collapse: collapse;
}

.previewTable td {
    padding: 10px;
    text-align: left;
}

.previewTable td:first-child {
    font-weight: bold;
    text-align: right;
}

/* Copy Button Styling */
.copyQuoteButton {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
}

.copyQuoteButton:hover {
    background-color: #0056b3;
}

.copyQuoteButton:active {
    background-color: #004085;
}