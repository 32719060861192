/* File: src/styles/Tours.module.css */
/* Purpose: Styling for Tours Frontpage and components */

/* General Layout for the Tours Frontpage */
.toursFrontpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

/* Search Bar Layout */
.toursSearchBar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1000px;
    gap: 15px; /* Increased spacing between dropdowns */
    margin-bottom: 25px; /* Increased spacing below the search bar */
}

/* Dropdown Styles */
.yearDropdown {
    min-width: 120px;
    max-width: 150px;
    flex-shrink: 0;
}

.regionDropdown{
    max-width: 175px; /* Increase the allowed size */
    flex-shrink: 0;   /* Prevent shrinking */
    flex-grow: 1;     /* Allow it to grow and fill available space */
}

.activityDropdown {
    max-width: 350px; /* Increase the allowed size */
    flex-shrink: 0;   /* Prevent shrinking */
    flex-grow: 1;     /* Allow it to grow and fill available space */
}

/* Rates Section Styling */
.ratesSection {
    width: 100%;
    max-width: 1200px;
    margin-top: 30px; /* Increased margin to separate from other sections */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px; /* Added padding for better spacing */
}

/* Shared Box for Quote Generator and Policy Section */
.toursQuotePolicyBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1000px;
    background-color: #f9f9f9; /* Subtle background color */
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 25px; /* Increased padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    gap: 30px; /* More spacing between sections */
    margin-bottom: 30px; /* Increased margin below */
}

/* Quote Generator Section */
.quoteGenerator {
    flex: 1;
    max-width: 480px;
    min-height: 350px; /* Ensure consistent height for alignment */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out inputs and summary */
}

/* Policy Section */
.policySection {
    flex: 1;
    max-width: 480px;
    min-height: 350px; /* Ensure consistent height for alignment */
    overflow-y: auto;
    padding-left: 20px; /* Space between divider and content */
    border-left: 2px solid #ddd; /* Divider between sections */
}

/* Section Titles */
.quoteGeneratorTitle,
.policySection h3 {
    font-size: 20px; /* Larger title size */
    font-weight: bold;
    color: #264653; /* Themed color */
    margin-bottom: 20px;
}

/* Quote Row Styling */
.quoteRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; /* Increased spacing */
    gap: 15px; /* Space between labels and inputs */
}

.quoteRow label {
    flex: 0 0 160px; /* Consistent label width */
    text-align: left;
    font-size: 16px; /* Larger label font size */
    color: #264653;
}

.quoteRow .ant-input-number {
    flex: 1;
    max-width: 150px; /* Consistent input size */
    border-radius: 4px; /* Rounded corners for inputs */
    border: 1px solid #ccc;
    padding: 5px; /* Better spacing inside inputs */
}

/* Dynamic Quote Summary */
.quoteResults {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin: 25px 0; /* Spacing around the summary */
    text-align: center; /* Ensure text aligns properly */
    background-color: #ffffff; /* Card background */
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px; /* Space inside the summary */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.previewTable {
    width: auto; /* Prevent table from stretching */
    margin: 0 auto; /* Center the table */
    border-collapse: collapse;
}

.previewTable td {
    padding: 10px;
    text-align: left; /* Align text for readability */
}

.previewTable td:first-child {
    font-weight: bold;
    text-align: right; /* Align labels to the right */
}

/* Copy Button Styling */
.copyQuoteButton {
    background-color: #2a9d8f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
}

.copyQuoteButton:hover {
    background-color: #21867a; /* Darker shade on hover */
}

.copyQuoteButton:active {
    background-color: #1b705f; /* Even darker on active */
}