/* File: src/styles/HotelRatesSearch.module.css */

/* Main Form Container */
.searchBarContainer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two-column layout */
  gap: 20px; /* Space between elements */
  padding: 32px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 640px;
  margin: 32px auto;
  border: 1px solid #e0e0e0;
}

/* Single-column layout for smaller screens */
@media (max-width: 600px) {
  .searchBarContainer {
    grid-template-columns: 1fr; /* Stack items vertically */
  }
}

/* Dropdown Labels */
.dropdownLabel {
  font-size: 16px;
  font-weight: 600;
  color: #4a4a4a;
  margin-bottom: 6px;
}

/* Dropdown Select Fields */
.dropdownSelect {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.dropdownSelect:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Number Input Fields (Adults & Children) */
.numberInputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.numberInput {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.numberInput:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  outline: none;
}

/* Date Picker Section */
.datePickerContainer {
  grid-column: span 2; /* Span across two columns */
  margin-bottom: 16px;
}

/* Reset Button Container */
.resetButtonContainer {
  grid-column: span 2; /* Ensures it spans the form width */
  text-align: right; /* Aligns the button to the right */
}

/* Reset Button */
.resetButton {
  padding: 8px 16px; /* Reduced padding for a smaller button */
  background-color: #ffffff; /* White background */
  color: #f44336; /* Light red text */
  border: 1px solid #f44336; /* Light red border */
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px; /* Smaller font size */
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.resetButton:hover {
  background-color: #fce4ec; /* Light pink hover background */
  color: #d32f2f; /* Darker red text on hover */
}

.resetButton:focus {
  box-shadow: 0 0 4px rgba(244, 67, 54, 0.5);
  outline: none;
}

/* Skeleton Placeholder for Loading State */
.skeletonPlaceholder {
  height: 40px;
  width: 100%;
  background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Rates Display Container */
.ratesDisplayContainer {
  display: flex;
  gap: 20px;
  padding: 32px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 960px;
  margin: 32px auto;
  border: 1px solid #e0e0e0;
}

/* Left Column: Summary Container */
.summaryContainer {
  flex: 1;
  padding: 16px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.summaryContainer h2 {
  margin-bottom: 16px;
  font-size: 20px;
  color: #333;
}

.summaryContainer p {
  margin: 4px 0;
  font-size: 16px;
  color: #555;
}

/* Toggle Button for Daily Breakdown */
.toggleButton {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 12px;
  transition: background-color 0.3s ease;
}

.toggleButton:hover {
  background-color: #0056b3;
}

/* Right Column: Policy Container */
.policyContainer {
  flex: 1;
  padding: 16px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.policyContainer h2 {
  margin-bottom: 16px;
  font-size: 20px;
  color: #333;
}

.policyDescription p {
  margin: 8px 0;
  font-size: 16px;
  color: #555;
}

.pdfLink a {
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
}

.pdfLink a:hover {
  text-decoration: underline;
}