/* File: src/styles/NavigationBar.module.css */

.navbarContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    background-color: #f9f9f9;
    padding: 0 16px;
  }
  
  .navbarLogo {
    display: flex;
    align-items: center;
    padding: 12px 0; /* Add spacing above and below the logo */
  }
  
  .navbarLogo img {
    height: 60px;
    width: auto;
  }
  
  .menu {
    background: transparent;
    border-bottom: none; /* Remove menu underline */
  }
  
  .menu .ant-menu-item {
    color: #333 !important;
    font-weight: 500;
    font-size: 16px;
    padding-left: 0; /* Indent left */
  }
  
  .menu .ant-menu-item:hover {
    color: #1890ff !important;
  }
  
  .menu .ant-menu-item-selected {
    color: #1890ff !important;
    font-weight: 600;
  }

  /* File: src/styles/NavigationBar.module.css */

/* File: src/styles/NavigationBar.module.css */

/* File: src/styles/NavigationBar.module.css */

.navbarsisterlogo {
  margin-left: auto; /* Push the logo to the right */
  margin-right: 10px; /* Add space from the right edge */
  object-fit: contain; /* Ensures the logo scales well */
}