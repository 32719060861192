/* File: src/styles/Databoard.module.css */
/* Purpose: Shared styles for the Databoard components and layout */

/* General Layout */
.layout-header {
  background-color: #001529;
  color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 10px;
}

.layout-sidebar {
  background-color: #001529;
}

.layout-content {
  padding: 24px;
  background-color: #fff;
}

/* Sidebar Menu */
.sidebar-menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
}

.sidebar-menu-item-active {
  background-color: #1890ff;
}

/* Welcome Message */
.welcome-container {
  text-align: center;
  margin-top: 20px;
}

.welcome-title {
  font-size: 24px;
  color: #001529;
}

.welcome-text {
  font-size: 16px;
  color: #595959;
}

.welcome-actions {
  margin-top: 20px;
}

/* Filters */
.filters-container {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.filter-dropdown {
  width: 100%;
}

.clear-filters-button {
  margin-top: 8px;
  color: #ff4d4f;
}

/* Table */
.table-container {
  margin-top: 16px;
}

.table-row-editable {
  background-color: #f6ffed;
}

.table-pagination {
  margin-top: 8px;
}

/* Modal */
.transfers-modal {
  padding: 24px;
}

.modal-title {
  font-size: 16px;
  font-weight: bold;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

/* Error and Loading Messages */
.error-message {
  color: #ff4d4f;
  font-size: 14px;
  margin-bottom: 10px;
}

.loading-message {
  font-size: 16px;
  color: #595959;
}