/* File: src/components/Databoard/Modules/Transfers/Transfers.module.css */
/* Purpose: Scoped styles for the Transfers module */

/* Page Title */
.page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form Section */
.unified-transfers-form {
  margin-bottom: 20px;
}

/* Table Section */
.transfers-table {
  margin-top: 20px;
}

/* Table Actions */
.table-actions button {
  margin-right: 8px;
}

/* Loading and Error States */
.loading-message {
  font-size: 16px;
  color: #595959;
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
}