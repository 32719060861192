/* File: src/styles/Transfers.module.css */

.transfersFrontpage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box; /* Ensure padding and border are included in the width and height */
}

.transfersSearchBar {
    display: flex; /* Align items horizontally */
    justify-content: center; /* Center the dropdowns horizontally */
    align-items: flex-start; /* Align items to the top */
    width: 100%;
    max-width: 1000px; /* Limit the total width of the search bar */
    gap: 10px; /* Add space between dropdowns */
    z-index: 1; /* Ensure the search bar stays on top */
    margin-bottom: 20px; /* Add spacing between the search bar and other elements */
}

.yearDropdown {
    width: auto; /* Let it adjust dynamically */
    min-width: 110px; /* Match the placeholder text */
    max-width: 150px; /* Add an upper limit for consistent styling */
    flex-shrink: 0; /* Prevent shrinking */
}

.pickupDropdown,
.dropoffDropdown {
    width: auto;
    max-width: 250px; /* Slightly reduce the width for consistency */
    flex-shrink: 1; /* Allow them to shrink if needed */
}
/* File: src/styles/Transfers.module.css */

.rateTableContainer {
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for modern design */
    border-radius: 8px; /* Rounded corners */
    overflow: hidden; /* Clip overflowing content */
    background-color: #ffffff; /* White background for clarity */
}

.rateTable {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px; /* Ensure rounded corners apply */
    overflow: hidden;
}

/* Header styling for the table */
.rateTable :global(.ant-table-thead > tr > th) {
    background-color: #d9eaff; /* Light blue background */
    color: #000000; /* Black text */
    font-weight: bold; /* Bold text */
    text-align: center;
    padding: 12px;
    font-size: 14px;
    border-bottom: 2px solid #ddd; /* Add distinct border */
}

/* Cell styling for the table body */
.rateTable :global(.ant-table-tbody > tr > td) {
    text-align: center;
    padding: 10px;
    font-size: 14px;
    color: #333; /* Dark text for readability */
    border-bottom: 1px solid #ddd; /* Light border for row separation */
}

/* Alternate row colors */
.rateTable :global(.ant-table-tbody > tr:nth-child(even)) {
    background-color: #f9f9f9; /* Light grey background for even rows */
}

/* Row hover effect */
.rateTable :global(.ant-table-tbody > tr:hover) {
    background-color: #f1f1f1; /* Highlight row on hover */
}

/* Copy button styling */
.copyButton {
    background-color: #007bff; /* Blue background for buttons */
    color: #ffffff;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.copyButton:hover {
    background-color: #0056b3;
}