/* File: src/styles/ShoppingCart.css */

/* General styling for the shopping cart */
.shopping-cart {
    max-width: 800px;
    margin: 50px auto;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

/* New class to hide the shopping cart when it's empty */
.shopping-cart.hidden {
    display: none;
}

.shopping-cart h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
}

.shopping-cart-table {
    width: 100%;
    border-collapse: collapse;
}

.shopping-cart-table th, .shopping-cart-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.shopping-cart-table th {
    background: #f8f8f8;
    color: #333;
    font-weight: bold;
}

.shopping-cart-table tbody tr:hover {
    background: #f1f1f1;
}

.shopping-cart-table tfoot td {
    font-weight: bold;
    font-size: 18px;
}

.shopping-cart-item .button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align buttons to the start */
}

.shopping-cart-item button {
    background: #ff4c4c;
    color: #fff;
    border: none;
    padding: 5px 10px; /* Smaller padding */
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-bottom: 5px; /* Smaller space between buttons */
    font-size: 14px; /* Smaller font size */
    width: 120px; /* Auto width to fit content */
}

.shopping-cart-item button:hover {
    background: #e04343;
}

.empty-cart-button {
    display: block;
    margin: 20px auto;
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 16px;
}

.empty-cart-button:hover {
    background: #0056b3;
}

.shopping-cart p {
    text-align: center;
    color: #666;
}

/* File: src/styles/ShoppingCart.css */

.shopping-cart.hidden {
    display: none; /* Hide the cart when it's empty */
}